import { Component, createRef } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { File, Info, Loading } from "./other";
import { deleteClient, editClient, getClient, getClientLoans, getFiles, newClientLoan } from "./api";
import { formatPhone } from "./utils";

import "./styles/client.scss";

class Client extends Component {

    constructor(props) {

        super(props);

        this.lastNameInput = createRef();
        this.firstNameInput = createRef();
        this.emailInput = createRef();
        this.phoneInput = createRef();
        this.cellphoneInput = createRef();
        this.addressInput = createRef();
        this.postalCodeInput = createRef();
        this.cityInput = createRef();

        this.requesting = 0;

        this.state = { requesting: false, info: null, client: null, files: [], editing: false, loans: [] };
    }

    setRequesting(requesting) {
        this.requesting += requesting ? 1 : -1;
        if (this.requesting === 0 && this.state.requesting)
            this.setState({ requesting: false });
        else if (this.requesting > 0 && !this.state.requesting)
            this.setState({ requesting: true });
    }

    componentDidMount() {

        const id = parseInt(this.props.params.id);
        if (isNaN(id)) {
            this.setState({ info: <Info>Ce numéro de client n'est pas valide !</Info> });
            return;
        }

        this.setState({ info: null });
        this.setRequesting(true);
        getClient(id).then((client) => {
            this.setRequesting(false);
            this.setState({ client });
        }).catch((error) => {
            this.setRequesting(false);
            if (error === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else if (error === "This client does not exist")
                this.setState({ info: <Info>Ce client n'existe pas !</Info> });
            else
                this.setState({ info: <Info>Un problème est survenu !</Info> });
        });

        this.setRequesting(true);
        getFiles({ client: id }).then((files) => {
            this.setRequesting(false);
            this.setState({ files });
        });

        this.setRequesting(true);
        getClientLoans(id).then((loans) => {
            this.setRequesting(false);
            this.setState({ loans: [...this.state.loans.filter((loan) => loans.every((l) => l.id !== loan.id)), ...loans] });
        });

        const [searchParams] = this.props.searchParams;
        if (searchParams.has("lending") && /^\d+$/.test(searchParams.get("lending"))) {

            const lending = parseInt(searchParams.get("lending"));

            this.setRequesting(true);
            newClientLoan(id, lending).then((loan) => {
                this.setRequesting(false);
                this.setState({ loans: [...this.state.loans.filter((l) => l.id !== loan.id), loan], info: <Info color="green">Le prêt a bien été enregistré</Info> }, () => this.props.navigate("/files/" + id));
            }).catch(() => {
                this.setRequesting(false);
            });
        }
    }

    render() {

        document.title = `Client ${this.state.client ? this.state.client.firstName + " " + this.state.client.lastName : ""} - Servelec SAV`;

        const formatPhones = () => {
            formatPhone(this.phoneInput.current);
            formatPhone(this.cellphoneInput.current);
        }

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true });
                return;
            }

            const edits = {};
            if (this.lastNameInput.current.value !== this.state.client.lastName) edits.lastName = this.lastNameInput.current.value;
            if (this.firstNameInput.current.value !== this.state.client.firstName) edits.firstName = this.firstNameInput.current.value;
            if (this.emailInput.current.value !== this.state.client.email) edits.email = this.emailInput.current.value;
            if (this.phoneInput.current.value !== this.state.client.phone) edits.phone = this.phoneInput.current.value;
            if (this.cellphoneInput.current.value !== this.state.client.cellphone) edits.cellphone = this.cellphoneInput.current.value;
            if (this.addressInput.current.value !== this.state.client.address) edits.address = this.addressInput.current.value;
            if (this.postalCodeInput.current.value !== this.state.client.postalCode) edits.postalCode = this.postalCodeInput.current.value;
            if (this.cityInput.current.value !== this.state.client.city) edits.city = this.cityInput.current.value;

            if (Object.keys(edits).length === 0) {
                this.setState({ editing: false });
                return;
            }

            this.setState({ info: null });
            this.setRequesting(true);
            editClient(this.state.client.id, edits).then((client) => {
                this.setRequesting(false);
                this.setState({ editing: false, client, files: this.state.files.map((file) => ({ ...file, client: { ...file.client, ...edits } })) });
            }).catch((error) => {
                this.setRequesting(false);
                if (error === "Invalid postal code")
                    this.setState({ info: <Info>Code postal invalide !</Info> });
                else
                    this.setState({ info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDelete = () => {

            const valid = window.confirm("Voulez vous vraiment supprimer le client ?");
            if (!valid) return;

            this.setState({ info: null });
            this.setRequesting(true);
            deleteClient(this.state.client.id).then(() => {
                this.props.navigate("/home");
            }).catch((error) => {
                this.setRequesting(false);
                if (error === "This client is used by files")
                    this.setState({ info: <Info>Ce client est utilisé par une ou plusieurs fiches !</Info> });
                else this.setState({ info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="client">

            <div className="title">Client{this.state.client ? ` n°${this.state.client.id}` : ""}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {!this.state.client ? null : <div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Nom :</div>
                        {this.state.editing
                            ? <input ref={this.lastNameInput} defaultValue={this.state.client.lastName} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.lastName || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Prénom :</div>
                        {this.state.editing
                            ? <input ref={this.firstNameInput} defaultValue={this.state.client.firstName} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.firstName || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Adresse email :</div>
                        {this.state.editing
                            ? <input ref={this.emailInput} defaultValue={this.state.client.email} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.email || <i>Non précisée</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Téléphone fixe :</div>
                        {this.state.editing
                            ? <input ref={this.phoneInput} defaultValue={this.state.client.phone} disabled={this.state.requesting} onChange={formatPhones}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.phone || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Téléphone portable :</div>
                        {this.state.editing
                            ? <input ref={this.cellphoneInput} defaultValue={this.state.client.cellphone} disabled={this.state.requesting} onChange={formatPhones}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.cellphone || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Adresse :</div>
                        {this.state.editing
                            ? <input ref={this.addressInput} defaultValue={this.state.client.address} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.address || <i>Non précisée</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Code postal :</div>
                        {this.state.editing
                            ? <input ref={this.postalCodeInput} defaultValue={this.state.client.postalCode} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.postalCode || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Ville :</div>
                        {this.state.editing
                            ? <input ref={this.cityInput} defaultValue={this.state.client.city} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.client.city || <i>Non précisée</i>}</div>}
                    </div>
                </div>

                <div className="subtitle">Entrée le :</div>
                <div>{moment(this.state.client.date).format("DD/MM/YYYY")}</div>

                <div>
                    <button disabled={this.state.requesting} onClick={toggleEdit}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={() => this.setState({ editing: false })}>Annuler</button> : null}
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={processDelete}>Supprimer</button> : null}
                </div>

                <div className="subtitle">Fiches :</div>
                <div className="files">{this.state.files.length === 0
                    ? "Aucune fiche"
                    : <table>
                        <thead><tr>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Téléphone fixe</th>
                            <th>Téléphone portable</th>
                            <th>Matériel</th>
                            <th>Statut</th>
                        </tr></thead>
                        <tbody>{this.state.files.map((file) => <File key={file.id} file={file} />)}</tbody>
                    </table>}</div>

                <div className="subtitle">Prêts :</div>
                <div className="loans">{this.state.loans.length === 0
                    ? "Aucun prêt"
                    : <table>
                        <thead><tr>
                            <th>Appareil</th>
                            <th>Fiche</th>
                            <th>Date de prêt</th>
                            <th>Rendu</th>
                        </tr></thead>
                        <tbody>{this.state.loans.sort((a, b) => b.loanDate - a.loanDate).map((loan) => <tr key={loan.id}>
                            <td className="clickable" onClick={() => this.props.navigate("/lentEquipments/" + loan.lentEquipment.id)}>{loan.lentEquipment.type.name + " " + loan.lentEquipment.brand.name}</td>
                            {loan.fileId
                                ? <td className="clickable" onClick={() => this.props.navigate("/files/" + loan.fileId)}>n°{loan.fileId}</td>
                                : <td>Aucune</td>}
                            <td>{moment(loan.loanDate).format("DD/MM/YYYY")}</td>
                            <td>{loan.returnDate ? moment(loan.returnDate).format("DD/MM/YYYY") : "Non"}</td>
                        </tr>)}</tbody>
                    </table>}</div>
                <div>
                    <Link className="button" to={"/lentEquipments?lending=C" + this.state.client.id}>Prêter un appareil</Link>
                </div>
            </div>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <Client {...props} params={useParams()} navigate={useNavigate()} searchParams={useSearchParams()} />;
