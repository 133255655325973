import { Component } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Brand, Client, Equipment, File, Info, LentEquipment, Loading, SparePart, Type } from "./other";
import { getClients, getEquipments, getEquipmentsBrands, getEquipmentsTypes, getFiles, getLentEquipments, getSpareParts } from "./api";

import "./styles/searchPage.scss";

class Search extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, query: null, queryType: null, lending: null, results: null };
    }

    componentDidMount() {
        this.processQuery();
    }

    componentDidUpdate(oldProps) {
        if (this.props.location.search !== oldProps.location.search) this.processQuery();
    }

    setQueryType(newQueryType) {

        const [searchParams] = this.props.searchParams;
        const queryType = searchParams.has("queryType") ? searchParams.get("queryType").toLowerCase() : "";
        if (queryType === newQueryType) return;

        searchParams.set("queryType", newQueryType);
        this.props.navigate("/search?" + searchParams.toString());
    }

    async processQuery() {

        const [searchParams] = this.props.searchParams;
        const query = searchParams.has("query") ? searchParams.get("query") : "";
        const queryType = searchParams.has("queryType") ? searchParams.get("queryType").toLowerCase() : "";
        const lending = searchParams.has("lending") ? searchParams.get("lending") : null;

        this.setState({ query, queryType, lending, results: null });

        if (!query) {
            this.setState({ info: <Info>Aucune valeur à rechercher n'a été spécifiée !</Info> });
            return;
        }

        if (query.length < 3) {
            this.setState({ info: <Info>La recherche doit faire minimum 3 caractères !</Info> });
            return;
        }

        if (!queryType) {
            this.setState({ info: <Info>Aucun type de recherche n'a été spécifié !</Info> });
            return;
        }

        if (!["files", "clients", "equipments", "equipmentstypes", "equipmentsbrands", "spareparts", "lentequipments"].includes(queryType)) {
            this.setState({ info: <Info>Ce type de recherche n'existe pas !</Info> });
            return;
        }

        this.setState({ requesting: true, info: null });

        let results = [];
        try {
            if (queryType === "files") results = await getFiles({ query });
            else if (queryType === "clients") results = await getClients({ query });
            else if (queryType === "equipments") results = await getEquipments({ query });
            else if (queryType === "equipmentstypes") results = await getEquipmentsTypes(query, true);
            else if (queryType === "equipmentsbrands") results = await getEquipmentsBrands(query, true);
            else if (queryType === "spareparts") results = await getSpareParts({ query });
            else if (queryType === "lentequipments") results = await getLentEquipments({ query, withLastLoanDates: true });
        } catch (message) {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            return;
        }

        this.setState({ requesting: false, results });
    }

    render() {

        document.title = `Résultats de recherche pour ${this.state.query} - Servelec SAV`;

        const lendingUrl = this.state.lending ? ("/" + (this.state.lending.slice(0, 1).toUpperCase() === "C" ? "clients" : "files") + "/" + this.state.lending.slice(1)) : null;

        let results;
        if (this.state.results && this.state.queryType === "files")
            results = <table>
                <thead><tr>
                    <th>Date</th>
                    <th>Client</th>
                    <th>Téléphone fixe</th>
                    <th>Téléphone portable</th>
                    <th>Matériel</th>
                    <th>Statut</th>
                </tr></thead>
                <tbody>{this.state.results.map((file) => <File key={file.id} file={file} />)}</tbody>
            </table>;
        else if (this.state.results && this.state.queryType === "clients")
            results = <table>
                <thead><tr>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Ville</th>
                    <th>Code postal</th>
                    <th>Adresse</th>
                    <th>Téléphone fixe</th>
                    <th>Téléphone portable</th>
                    <th>Adresse email</th>
                </tr></thead>
                <tbody>{this.state.results.map((client) => <Client key={client.id} client={client} />)}</tbody>
            </table>;
        else if (this.state.results && this.state.queryType === "equipments")
            results = <table>
                <thead><tr>
                    <th>Type</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Numéro de série</th>
                    <th>Numéro PNC</th>
                </tr></thead>
                <tbody>{this.state.results.map((equipment) => <Equipment key={equipment.id} equipment={equipment} />)}</tbody>
            </table>;
        else if (this.state.results && this.state.queryType === "equipmentstypes")
            results = <table>
                <thead><tr>
                    <th>Type</th>
                    <th>Nombre de fiches</th>
                </tr></thead>
                <tbody>{this.state.results.sort((a, b) => b.equipmentsCount - a.equipmentsCount).map((type) => <Type key={type.id} type={type} />)}</tbody>
            </table>;
        else if (this.state.results && this.state.queryType === "equipmentsbrands")
            results = <table>
                <thead><tr>
                    <th>Marque</th>
                    <th>Nombre de fiches</th>
                </tr></thead>
                <tbody>{this.state.results.sort((a, b) => b.equipmentsCount - a.equipmentsCount).map((brand) => <Brand key={brand.id} brand={brand} />)}</tbody>
            </table>;
        else if (this.state.results && this.state.queryType === "spareparts")
            results = <table>
                <thead><tr>
                    <th>Nom</th>
                    <th>Référence</th>
                    <th>Référence fournisseur</th>
                    <th>Fabriquant</th>
                    <th>Prix d'achat HT</th>
                    <th>Stock réel</th>
                    <th>Position</th>
                    <th>Commentaires</th>
                </tr></thead>
                <tbody>{this.state.results.map((sparePart) => <SparePart key={sparePart.id} sparePart={sparePart} />)}</tbody>
            </table>;
        else if (this.state.results && this.state.queryType === "lentequipments")
            results = <table>
                <thead><tr>
                    <th>Type</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Numéro de série</th>
                    <th>Numéro PNC</th>
                    <th>Dernière révision</th>
                    <th>Prêté</th>
                    <th>Rendu</th>
                </tr></thead>
                <tbody>{this.state.results.map((lentEquipment) => <LentEquipment key={lentEquipment.id} lentEquipment={lentEquipment} url={lendingUrl ? (lendingUrl + "?lending=" + lentEquipment.id) : null} />)}</tbody>
            </table>;

        return <div className="searchPage">

            <div className="title">Résultats de recherche pour {this.state.query} :</div>

            <div className="box lists">
                {!this.state.lending ? <>
                    <button disabled={this.state.requesting} className={this.state.queryType === "files" ? "selected" : ""} onClick={() => this.setQueryType("files")}>Fiches</button>
                    <button disabled={this.state.requesting} className={this.state.queryType === "clients" ? "selected" : ""} onClick={() => this.setQueryType("clients")}>Clients</button>
                    <button disabled={this.state.requesting} className={this.state.queryType === "equipments" ? "selected" : ""} onClick={() => this.setQueryType("equipments")}>Matériels</button>
                    <button disabled={this.state.requesting} className={this.state.queryType === "equipmentstypes" ? "selected" : ""} onClick={() => this.setQueryType("equipmentsTypes")}>Types</button>
                    <button disabled={this.state.requesting} className={this.state.queryType === "equipmentsbrands" ? "selected" : ""} onClick={() => this.setQueryType("equipmentsBrands")}>Marques</button>
                    <button disabled={this.state.requesting} className={this.state.queryType === "spareparts" ? "selected" : ""} onClick={() => this.setQueryType("spareParts")}>Pièces détachées</button>
                    <button disabled={this.state.requesting} className={this.state.queryType === "lentequipments" ? "selected" : ""} onClick={() => this.setQueryType("lentEquipments")}>Appareils de prêt</button>
                </> : <div>Veuillez choisir l'appareil à prêter, vous pouvez utiliser la recherche</div>}
            </div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {this.state.results
                ? (this.state.results.length > 0
                    ? <div className="files">{results}</div>
                    : <div className="noresults">Aucun résultat</div>)
                : null}
        </div>
    }
}

// eslint-disable-next-line
export default (props) => <Search {...props} navigate={useNavigate()} location={useLocation()} searchParams={useSearchParams()} />;
