import { Component, createRef } from "react";
import { Info, Loading } from "./other";
import { login } from "./api";

import "./styles/login.scss";

export default class Login extends Component {

    constructor(props) {

        super(props);

        this.inputRef = createRef();

        this.state = { requesting: false, info: null };
    }

    render() {

        document.title = "Connexion - Servelec SAV";

        const processLogin = () => {

            this.setState({ requesting: true, info: null });
            login(this.inputRef.current.value).then((token) => {
                localStorage.setItem("token", token);
                window.location.reload();
            }).catch((message) => {
                if (message === "Invalid password" || message === "Missing password")
                    this.setState({ requesting: false, info: <Info>Mot de passe invalide !</Info> });
                else if (message === "Too many login fails")
                    this.setState({ requesting: false, info: <Info>Trop d'échecs, réessayez plus tard !</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="login">

            <div className="title">{document.title}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div><input ref={this.inputRef} type="password" autoFocus disabled={this.state.requesting} placeholder="Mot de passe"
                onKeyDown={(event) => { if (event.key === "Enter") processLogin(); }} /></div>
            <div><button disabled={this.state.requesting} onClick={processLogin}>Se connecter</button></div>
        </div>
    }
}