import { Component, createRef } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Info, Loading, TroubleshootingPaymentInput } from "./other";
import { deleteComment, deleteFile, editComment, editFile, getComments, getFile, getFileLoans, getFilesStates, newComment, newFileLoan, getFileImages, getFileImageUrl, deleteFileImage, newFileImage } from "./api";

import "./styles/file.scss";

class File extends Component {

    constructor(props) {

        super(props);

        this.symptomsInput = createRef();
        this.troubleshootingPaymentInput = createRef();
        this.stateInput = createRef();
        this.priorityInput = createRef();
        this.affectsStatisticsInput = createRef();

        this.requesting = 0;

        this.state = { requesting: false, info: null, file: null, editing: false, states: [], selectedDate: null, loans: [], displayAffectsStatisticsInput: false };
    }

    setRequesting(requesting) {
        this.requesting += requesting ? 1 : -1;
        if (this.requesting === 0 && this.state.requesting)
            this.setState({ requesting: false });
        else if (this.requesting > 0 && !this.state.requesting)
            this.setState({ requesting: true });
    }

    componentDidMount() {

        const id = parseInt(this.props.params.id);
        if (isNaN(id)) {
            this.setState({ info: <Info>Ce numéro de fiche n'est pas valide !</Info> });
            return;
        }

        this.setState({ info: null, file: null, editing: false, states: [], selectedDate: null, loans: [], displayAffectsStatisticsInput: false });

        this.setRequesting(true);
        getFile(id).then((file) => {
            this.setRequesting(false);
            this.setState({ file, selectedDate: new Date(file.date) });
        }).catch((error) => {
            this.setRequesting(false);
            if (error === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else if (error === "This file does not exist")
                this.setState({ info: <Info>Cette fiche n'existe pas !</Info> });
            else
                this.setState({ info: <Info>Un problème est survenu !</Info> });
        });

        this.setRequesting(true);
        getFilesStates().then((states) => {
            this.setRequesting(false);
            this.setState({ states });
        }).catch(() => {
            this.setRequesting(false);
        });

        this.setRequesting(true);
        getFileLoans(id).then((loans) => {
            this.setRequesting(false);
            this.setState({ loans: [...this.state.loans.filter((loan) => loans.every((l) => l.id !== loan.id)), ...loans] });
        }).catch(() => {
            this.setRequesting(false);
        });

        const [searchParams] = this.props.searchParams;
        if (searchParams.has("lending") && /^\d+$/.test(searchParams.get("lending"))) {

            const lending = parseInt(searchParams.get("lending"));

            this.setRequesting(true);
            newFileLoan(id, lending).then((loan) => {
                this.setRequesting(false);
                this.setState({ loans: [...this.state.loans.filter((l) => l.id !== loan.id), loan], info: <Info color="green">Le prêt a bien été enregistré</Info> }, () => this.props.navigate("/files/" + id));
            }).catch(() => {
                this.setRequesting(false);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params))
            this.componentDidMount();
    }

    render() {

        document.title = `Fiche ${this.state.file ? this.state.file.client.firstName + " " + this.state.file.client.lastName + " " + this.state.file.equipment.type.name + " " + this.state.file.equipment.brand.name : ""} - Servelec SAV`;

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true });
                return;
            }

            const edits = {};
            if (this.symptomsInput.current.value !== this.state.file.symptoms) edits.symptoms = this.symptomsInput.current.value;
            if (this.troubleshootingPaymentInput.current.value !== this.state.file.troubleShootingPayment) edits.troubleShootingPayment = this.troubleshootingPaymentInput.current.value;
            if (this.state.selectedDate.getTime() !== this.state.file.date) edits.date = this.state.selectedDate.getTime();
            if (parseInt(this.stateInput.current.value) !== this.state.file.state.id) edits.state = parseInt(this.stateInput.current.value);
            if (this.priorityInput.current.checked !== this.state.file.priority) edits.priority = this.priorityInput.current.checked;
            if (this.affectsStatisticsInput.current) edits.affectsStatistics = this.affectsStatisticsInput.current.checked;

            if (Object.keys(edits).length === 0) {
                this.setState({ editing: false });
                return;
            }

            this.setState({ info: null });
            this.setRequesting(true);
            editFile(this.state.file.id, edits).then((file) => {
                this.setRequesting(false);
                this.setState({ editing: false, displayAffectsStatisticsInput: false, file });
            }).catch(() => {
                this.setRequesting(false);
                this.setState({ info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDelete = () => {

            const valid = window.confirm("Voulez vous vraiment supprimer la fiche et ses commentaires ? (Le client et le matériel seront sauvegardés)");
            if (!valid) return;

            this.setState({ info: null });
            this.setRequesting(true);
            deleteFile(this.state.file.id).then(() => {
                this.props.navigate("/home");
            }).catch(() => {
                this.setRequesting(false);
                this.setState({ info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="file">

            <div className="title">Fiche{this.state.file ? ` n°${this.state.file.id} entrée le ${moment(this.state.file.date).format("DD/MM/YYYY")}` : ""}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {!this.state.file ? null : <div>

                <div className="subtitle">Client : <Link to={"/clients/" + this.state.file.client.id} className="link"><i className="fas fa-pen" /> Voir/modifier</Link></div>
                <div className="client-equipment-table">
                    <table>
                        <thead><tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Ville</th>
                            <th>Code postal</th>
                            <th>Adresse</th>
                            <th>Téléphone fixe</th>
                            <th>Téléphone portable</th>
                            <th>Adresse email</th>
                        </tr></thead>
                        <tbody><tr>
                            <td>{this.state.file.client.lastName}</td>
                            <td>{this.state.file.client.firstName}</td>
                            <td>{this.state.file.client.city}</td>
                            <td>{this.state.file.client.postalCode}</td>
                            <td>{this.state.file.client.address}</td>
                            <td>{this.state.file.client.phone}</td>
                            <td>{this.state.file.client.cellphone}</td>
                            <td>{this.state.file.client.email}</td>
                        </tr></tbody>
                    </table>
                </div>

                <div className="subtitle">Matériel : <Link to={"/equipments/" + this.state.file.equipment.id} className="link"><i className="fas fa-pen" /> Voir/modifier</Link></div>
                <div className="client-equipment-table">
                    <table>
                        <thead><tr>
                            <th>Type</th>
                            <th>Marque</th>
                            <th>Modèle</th>
                            <th>Numéro de série</th>
                            <th>Numéro PNC</th>
                        </tr></thead>
                        <tbody><tr>
                            <td>{this.state.file.equipment.type.name}</td>
                            <td>{this.state.file.equipment.brand.name}</td>
                            <td>{this.state.file.equipment.model}</td>
                            <td>{this.state.file.equipment.serialNumber}</td>
                            <td>{this.state.file.equipment.pnc}</td>
                        </tr></tbody>
                    </table>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Symptômes :</div>
                        {this.state.editing
                            ? <input ref={this.symptomsInput} defaultValue={this.state.file.symptoms} autoFocus disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.file.symptoms}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Règlement de la recherche de panne :</div>
                        {this.state.editing
                            ? <TroubleshootingPaymentInput ref={this.troubleshootingPaymentInput} defaultValue={this.state.file.troubleShootingPayment} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div style={{ color: this.state.file.troubleShootingPayment ? "" : "red" }}>{this.state.file.troubleShootingPayment || "Non payée !!!"}</div>}
                    </div>
                </div>

                <div className="field-group">

                    <div className="field">
                        <div className="subtitle">Etat de la fiche :</div>
                        {this.state.editing
                            ? <select ref={this.stateInput} defaultValue={this.state.file.state.id} disabled={this.state.requesting} style={{ color: "green" }}
                                onChange={(event) => this.setState({ displayAffectsStatisticsInput: parseInt(event.target.value) !== this.state.file.state.id && [14, 17, 15, 16].includes(parseInt(event.target.value)) })}>
                                {this.state.states.map((state) => <option value={state.id} key={state.id}>{state.name}</option>)}</select>
                            : <div style={{ color: "green" }}>{this.state.file.state.name}</div>}
                    </div>

                    <div className="field">
                        <div className="subtitle">Prioritaire :</div>
                        {this.state.editing
                            ? <input ref={this.priorityInput} type="checkbox" defaultChecked={this.state.file.priority} disabled={this.state.requesting} />
                            : <div style={{ color: this.state.file.priority ? "red" : "" }}>{this.state.file.priority ? "Oui" : "Non"}</div>}
                    </div>

                </div>

                {!this.state.displayAffectsStatisticsInput ? null : <div className="field-group">
                    <div className="field">
                        <div className="subtitle affectsStatistics">Affecter les statistiques :</div>
                        <input ref={this.affectsStatisticsInput} type="checkbox" defaultChecked={true} disabled={this.state.requesting} />
                    </div>
                </div>}

                {!this.state.editing ? null : <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Date d'entrée :</div>
                        <DatePicker disabled={this.state.requesting} dateFormat={"dd/MM/yyyy"} selected={this.state.selectedDate}
                            onChange={(date) => this.setState({ selectedDate: date || new Date() })}
                            onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null}
                            customInput={<input className="date" />} />
                    </div>
                </div>}

                <div>
                    <button disabled={this.state.requesting} onClick={toggleEdit}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={() => this.setState({ editing: false, displayAffectsStatisticsInput: false })}>Annuler</button> : null}
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={processDelete}>Supprimer</button> : null}
                </div>

                <div className="subtitle">Commentaires :</div>
                <Comments file={this.state.file} />

                <div className="subtitle">Prêts :</div>
                <div className="loans">
                    {this.state.loans.length === 0
                        ? "Aucun prêt"
                        : <table>
                            <thead><tr>
                                <th>Matériel</th>
                                <th>Date de prêt</th>
                                <th>Rendu</th>
                            </tr></thead>
                            <tbody>{this.state.loans.sort((a, b) => b.loanDate - a.loanDate).map((loan) => <tr key={loan.id}>
                                <td className="clickable" onClick={() => this.props.navigate("/lentEquipments/" + loan.lentEquipment.id)}>{loan.lentEquipment.type.name + " " + loan.lentEquipment.brand.name}</td>
                                <td>{moment(loan.loanDate).format("DD/MM/YYYY")}</td>
                                <td>{loan.returnDate ? moment(loan.returnDate).format("DD/MM/YYYY") : "Non"}</td>
                            </tr>)}</tbody>
                        </table>}
                </div>
                <div>
                    <Link className="button" to={"/lentEquipments?lending=F" + this.state.file.id}>Prêter un appareil</Link>
                </div>

                <div className="subtitle">Photos :</div>
                <Images file={this.state.file} />
            </div>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <File {...props} navigate={useNavigate()} params={useParams()} searchParams={useSearchParams()} />;

class Comments extends Component {

    constructor(props) {

        super(props);

        this.commentingInput = createRef();
        this.state = { requesting: false, comments: [], commenting: false };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getComments(this.props.file.id)
            .then((comments) => this.setState({ requesting: false, comments }))
            .catch(() => this.setState({ requesting: false }));
    }

    render() {

        const addComment = () => {

            if (this.commentingInput.current.value === "")
                return;

            this.setState({ requesting: true });
            newComment(this.props.file.id, this.commentingInput.current.value).then((comment) => {
                this.state.comments.push(comment);
                this.setState({ requesting: false, comments: this.state.comments, commenting: false });
            }).catch(() => this.setState({ requesting: false }));
        }

        const setRequesting = (requesting) => this.setState({ requesting });

        return <div>

            {this.state.requesting ? <Loading /> : null}

            <div className="comments">{this.state.comments.length === 0 && !this.state.commenting
                ? "Aucun commentaire"
                : <table>
                    <tbody>
                        {this.state.comments.map((comment) => <Comment file={this.props.file} comment={comment} setRequesting={setRequesting} onDelete={() => {
                            this.state.comments.splice(this.state.comments.indexOf(comment), 1);
                            this.setState({ comments: this.state.comments });
                        }} key={comment.id} />)}
                        {!this.state.commenting ? null
                            : <tr className="comment" key="new">
                                <td>{moment(Date.now()).format("DD/MM/YYYY")}</td>
                                <td><input ref={this.commentingInput} disabled={this.state.requesting} autoFocus onKeyDown={(event) => event.key === "Enter" ? addComment() : null} /></td>
                                <td>
                                    <button disabled={this.state.requesting} onClick={() => addComment()}>Sauvegarder</button>
                                    <button disabled={this.state.requesting} onClick={() => this.setState({ commenting: false })}>Annuler</button>
                                </td>
                            </tr>}
                    </tbody>
                </table>}</div>

            {this.state.commenting ? null
                : <button disabled={this.state.requesting} onClick={() => this.setState({ commenting: true })}>Nouveau commentaire</button>}
        </div>;
    }
}

class Comment extends Component {

    constructor(props) {

        super(props);

        this.editingInput = createRef();
        this.state = { requesting: false, editing: false };
    }

    setRequesting(requesting) {
        this.props.setRequesting(requesting);
        this.setState({ requesting });
    }

    render() {

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true });
                return;
            }

            if (this.editingInput.current.value === this.props.comment.comment) {
                this.setState({ editing: false });
                return;
            }

            this.setRequesting(true);
            editComment(this.props.file.id, this.props.comment.id, this.editingInput.current.value).then(() => {
                this.props.comment.comment = this.editingInput.current.value;
                this.setRequesting(false);
                this.setState({ editing: false });
            }).catch(() => {
                this.setRequesting(false);
                this.setState({ editing: false });
            });
        }

        const processDelete = () => {
            this.setRequesting(true);
            deleteComment(this.props.file.id, this.props.comment.id).then(() => {
                this.setRequesting(false);
                this.props.onDelete();
            }).catch(() => this.setRequesting(false));
        }

        return <tr className="comment">
            <td>{moment(this.props.comment.date).format("DD/MM/YYYY")}</td>
            <td>{this.state.editing
                ? <input ref={this.editingInput} autoFocus defaultValue={this.props.comment.comment} disabled={this.state.requesting} onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                : this.props.comment.comment}</td>
            <td>
                <button onClick={toggleEdit} disabled={this.state.requesting}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                {this.state.editing ? <button onClick={processDelete} disabled={this.state.requesting}>Supprimer</button> : null}
            </td>
        </tr>;
    }
}

class Images extends Component {

    constructor(props) {

        super(props);

        this.fileInputRef = createRef();

        this.state = { requesting: false, info: null, images: [] };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getFileImages(this.props.file.id).then((images) => {
            this.setState({ requesting: false, images });
        }).catch(() => {
            this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        const processImageNew = () => {

            this.setState({ requesting: true });

            const file = this.fileInputRef.current.files[0];
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.addEventListener("loadend", () => {

                newFileImage(this.props.file.id, reader.result).then((image) => {
                    this.setState({ requesting: false, images: [...this.state.images, image] });
                }).catch(() => {
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
                });
            });
        }

        return <div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            {this.state.images.length === 0 ? <div>Aucune photo</div> : <div className="images">
                {this.state.images.map((image) => <Image key={image.id} file={this.props.file} image={image}
                    requesting={this.state.requesting} setRequesting={(requesting) => this.setState({ requesting })} setInfo={(info) => this.setState({ info })}
                    onDelete={() => this.setState({ images: this.state.images.filter((i) => image.id !== i.id) })} />)}
            </div>}

            <input type="file" ref={this.fileInputRef} onInput={processImageNew} />
            <button disabled={this.state.requesting} onClick={() => this.fileInputRef.current.click()}>Importer une photo</button>

        </div>;
    }
}

class Image extends Component {

    constructor(props) {

        super(props);

        this.state = { imageUrl: null };
    }

    componentDidMount() {

        this.props.setRequesting(true);
        getFileImageUrl(this.props.file.id, this.props.image.id).then((imageUrl) => {
            this.props.setRequesting(false);
            this.setState({ imageUrl });
        }).catch(() => {
            this.props.setRequesting(false);
            this.props.setInfo(<Info>Un problème est survenu !</Info>);
        });
    }

    render() {

        const processOpen = () => {

            if (this.props.requesting) return;

            this.props.setRequesting(true);
            this.props.setInfo(null);
            getFileImageUrl(this.props.file.id, this.props.image.id).then((imageUrl) => {
                window.location.href = imageUrl;
            }).catch(() => {
                this.props.setRequesting(false);
                this.props.setInfo(<Info>Un problème est survenu !</Info>);
            });
        }

        const processDelete = (event) => {

            event.stopPropagation();

            if (!window.confirm("Voulez vous vraiment supprimer cette photo ?")) return;

            this.props.setRequesting(true);
            this.props.setInfo(null);
            deleteFileImage(this.props.file.id, this.props.image.id).then(() => {
                this.props.setRequesting(false);
                this.props.onDelete();
            }).catch(() => {
                this.props.setRequesting(false);
                this.props.setInfo(<Info>Un problème est survenu !</Info>);
            });
        }

        const processDownload = (event) => {

            event.stopPropagation();

            this.props.setRequesting(true);
            this.props.setInfo(null);
            getFileImageUrl(this.props.file.id, this.props.image.id, true).then((imageUrl) => {
                window.open(imageUrl);
                this.props.setRequesting(false);
            }).catch(() => {
                this.props.setRequesting(false);
                this.props.setInfo(<Info>Un problème est survenu !</Info>);
            });
        }

        if (!this.state.imageUrl) return null;

        return <div onClick={processOpen} className="image">
            <img src={this.state.imageUrl} alt="" />
            <div>
                <button onClick={processDownload} disabled={this.props.requesting}>Télécharger</button>
                <button onClick={processDelete} disabled={this.props.requesting}>Supprimer</button>
            </div>
        </div>;
    }
}
