import { Component, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { File, Info, Loading, SearchBar } from "./other";
import { deleteEquipment, editEquipment, getEquipment, getEquipmentsBrands, getEquipmentsTypes, getFiles } from "./api";

import "./styles/equipment.scss";

class Equipment extends Component {

    constructor(props) {

        super(props);

        this.typeInput = createRef();
        this.brandInput = createRef();
        this.modelInput = createRef();
        this.serialNumberInput = createRef();
        this.pncInput = createRef();

        this.state = { requesting: false, info: null, equipment: null, files: [], editing: false };
    }

    componentDidMount() {

        const id = parseInt(this.props.params.id);
        if (isNaN(id)) {
            this.setState({ info: <Info>Ce numéro de matériel n'est pas valide !</Info> });
            return;
        }

        this.setState({ requesting: true, info: null });
        getEquipment(id).then((equipment) => {
            this.setState({ equipment });
            getFiles({ equipment: equipment.id }).then((files) => this.setState({ requesting: false, files }));
        }).catch((error) => {
            if (error === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else if (error === "This equipment does not exist")
                this.setState({ requesting: false, info: <Info>Ce matériel n'existe pas !</Info> });
            else
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = `Matériel ${this.state.equipment ? this.state.equipment.type.name + " " + this.state.equipment.brand.name : ""} - Servelec SAV`;

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true }, () => {
                    this.typeInput.current.setValue(this.state.equipment.type.id);
                    this.brandInput.current.setValue(this.state.equipment.brand.id);
                });
                return;
            }

            const edits = {};
            if (this.typeInput.current.value !== this.state.equipment.type.id) edits.type = this.typeInput.current.value;
            if (this.brandInput.current.value !== this.state.equipment.brand.id) edits.brand = this.brandInput.current.value;
            if (this.modelInput.current.value !== this.state.equipment.model) edits.model = this.modelInput.current.value;
            if (this.serialNumberInput.current.value !== this.state.equipment.serialNumber) edits.serialNumber = this.serialNumberInput.current.value;
            if (this.pncInput.current.value !== this.state.equipment.pnc) edits.pnc = this.pncInput.current.value;

            if (Object.keys(edits).length === 0) {
                this.setState({ editing: false });
                return;
            }

            this.setState({ requesting: true, info: null });
            editEquipment(this.state.equipment.id, edits).then((equipment) => {
                this.setState({ editing: false, equipment, files: [] });
                getFiles({ equipment: equipment.id }).then((files) => this.setState({ requesting: false, files }));
            }).catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        const processDelete = () => {

            const valid = window.confirm("Voulez vous vraiment supprimer le matériel ?");
            if (!valid) return;

            this.setState({ requesting: true, info: null });
            deleteEquipment(this.state.equipment.id)
                .then(() => this.props.navigate("/home"))
                .catch((error) => {
                    if (error === "This equipment is used by files")
                        this.setState({ requesting: false, info: <Info>Ce matériel est utilisé par une ou plusieurs fiches !</Info> });
                    else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
                });
        }

        return <div className="equipment">

            <div className="title">Matériel{this.state.equipment ? ` n°${this.state.equipment.id}` : ""}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {!this.state.equipment ? null : <div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Type :</div>
                        {this.state.editing
                            ? <SearchBar ref={this.typeInput} disabled={this.state.requesting} onEnter={() => toggleEdit()}
                                getOptions={async (value) => (await getEquipmentsTypes(value)).map((type) => ({ name: type.name, value: type.id }))} />
                            : <div>{this.state.equipment.type.name || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Marque :</div>
                        {this.state.editing
                            ? <SearchBar ref={this.brandInput} disabled={this.state.requesting} onEnter={() => toggleEdit()}
                                getOptions={async (value) => (await getEquipmentsBrands(value)).map((brand) => ({ name: brand.name, value: brand.id }))} />
                            : <div>{this.state.equipment.brand.name || <i>Non précisée</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Modèle :</div>
                        {this.state.editing
                            ? <input ref={this.modelInput} defaultValue={this.state.equipment.model} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.equipment.model || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Numéro de série :</div>
                        {this.state.editing
                            ? <input ref={this.serialNumberInput} defaultValue={this.state.equipment.serialNumber} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.equipment.serialNumber || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Numéro PNC :</div>
                        {this.state.editing
                            ? <input ref={this.pncInput} defaultValue={this.state.equipment.pnc} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.equipment.pnc || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="subtitle">Entrée le :</div>
                <div>{moment(this.state.equipment.date).format("DD/MM/YYYY")}</div>

                <div>
                    <button disabled={this.state.requesting} onClick={toggleEdit}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={() => this.setState({ editing: false })}>Annuler</button> : null}
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={processDelete}>Supprimer</button> : null}
                </div>

                <div className="subtitle">Fiches :</div>
                <div className="files">{this.state.files.length === 0
                    ? "Aucune fiche"
                    : <table>
                        <thead><tr>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Téléphone fixe</th>
                            <th>Téléphone portable</th>
                            <th>Matériel</th>
                            <th>Statut</th>
                        </tr></thead>
                        <tbody>{this.state.files.map((file) => <File key={file.id} file={file} />)}</tbody>
                    </table>}</div>
            </div>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <Equipment {...props} params={useParams()} navigate={useNavigate()} />;
