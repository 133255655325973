import { Component, createRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Info, Loading, SearchBar, TroubleshootingPaymentInput } from "./other";
import { getClients, getEquipments, getEquipmentsBrands, getEquipmentsTypes, newFile } from "./api";
import { formatPhone } from "./utils";

import "./styles/newFile.scss";

class NewFile extends Component {

    constructor(props) {

        super(props);

        this.client = {
            lastName: createRef(),
            firstName: createRef(),
            city: createRef(),
            postalCode: createRef(),
            address: createRef(),
            phone: createRef(),
            cellphone: createRef(),
            email: createRef()
        };
        this.equipment = {
            type: createRef(),
            brand: createRef(),
            model: createRef(),
            pnc: createRef(),
            serialNumber: createRef()
        };
        this.other = {
            symptoms: createRef(),
            troubleShootingPayment: createRef(),
            priority: createRef(),
            date: createRef(),
            createButton: createRef()
        };

        this.state = {
            requesting: false,
            info: null,
            clients: null,
            clientId: -1,
            equipments: null,
            equipmentId: -1,
            selectedDate: new Date()
        };
    }

    render() {

        document.title = "Nouvelle fiche - Servelec SAV";

        const updateClients = () => {

            formatPhone(this.client.phone.current);
            formatPhone(this.client.cellphone.current);

            if (!this.client.lastName.current.value && !this.client.firstName.current.value
                && !this.client.address.current.value && !this.client.city.current.value && !this.client.postalCode.current.value
                && !this.client.phone.current.value && !this.client.cellphone.current.value && !this.client.email.current.value) {
                this.setState({ clients: null, clientId: -1 });
                return;
            }

            const options = {};
            if (this.client.lastName.current.value) options.lastName = this.client.lastName.current.value;
            if (this.client.firstName.current.value) options.firstName = this.client.firstName.current.value;
            if (this.client.email.current.value) options.email = this.client.email.current.value;
            if (this.client.phone.current.value) options.phone = this.client.phone.current.value;
            if (this.client.cellphone.current.value) options.cellphone = this.client.cellphone.current.value;
            if (this.client.city.current.value) options.city = this.client.city.current.value;
            if (this.client.postalCode.current.value) options.postalCode = this.client.postalCode.current.value;
            if (this.client.address.current.value) options.address = this.client.address.current.value;

            getClients(options).then((clients) => {
                this.setState({ clients: clients, clientId: -1 });
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    window.location.reload();
                }
            });
        }

        let clientResults = null;
        if (this.state.clientId > 0)
            clientResults = <span>Modifiez pour rechercher</span>;
        else if (!this.state.clients)
            clientResults = <span>Commencez à remplir pour rechercher</span>;
        else if (this.state.clients.length === 0)
            clientResults = <span>Aucun résultat</span>;
        else
            clientResults = <table>
                <thead><tr>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Ville</th>
                    <th>Code postal</th>
                    <th>Adresse</th>
                    <th>Téléphone fixe</th>
                    <th>Téléphone portable</th>
                    <th>Adresse email</th>
                </tr></thead>
                <tbody>{this.state.clients.map((client) => <tr key={client.id} onClick={() => {
                    this.client.lastName.current.value = client.lastName;
                    this.client.firstName.current.value = client.firstName;
                    this.client.address.current.value = client.address;
                    this.client.city.current.value = client.city;
                    this.client.postalCode.current.value = client.postalCode;
                    this.client.phone.current.value = client.phone;
                    this.client.cellphone.current.value = client.cellphone;
                    this.client.email.current.value = client.email;
                    this.equipment.type.current.focus();
                    this.setState({ clientId: client.id });
                }}>
                    <td>{client.lastName}</td>
                    <td>{client.firstName}</td>
                    <td>{client.city}</td>
                    <td>{client.postalCode}</td>
                    <td>{client.address}</td>
                    <td>{client.phone}</td>
                    <td>{client.cellphone}</td>
                    <td>{client.email}</td>
                </tr>)}</tbody>
            </table>;

        const updateEquipments = () => {

            if (typeof this.equipment.type.current.value !== "number" && !this.equipment.type.current.value
                && typeof this.equipment.brand.current.value !== "number" && !this.equipment.brand.current.value
                && !this.equipment.model.current.value
                && !this.equipment.serialNumber.current.value
                && !this.equipment.pnc.current.value) {
                this.setState({ equipments: null, equipmentId: -1 });
                return;
            }

            const options = {};
            if (typeof this.equipment.type.current.value === "number") options.typeId = this.equipment.type.current.value;
            else if (this.equipment.type.current.value) options.type = this.equipment.type.current.value;
            if (typeof this.equipment.brand.current.value === "number") options.brandId = this.equipment.brand.current.value;
            else if (this.equipment.brand.current.value) options.brand = this.equipment.brand.current.value;
            if (this.equipment.model.current.value) options.model = this.equipment.model.current.value;
            if (this.equipment.serialNumber.current.value) options.serialNumber = this.equipment.serialNumber.current.value;
            if (this.equipment.pnc.current.value) options.pnc = this.equipment.pnc.current.value;

            getEquipments(options).then((equipments) => {
                this.setState({ equipments: equipments, equipmentId: -1 });
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    window.location.reload();
                }
            });
        }

        let equipmentResults = null;
        if (this.state.equipmentId > 0)
            equipmentResults = <span>Modifiez pour rechercher</span>;
        else if (!this.state.equipments)
            equipmentResults = <span>Commencez à remplir pour rechercher</span>;
        else if (this.state.equipments.length === 0)
            equipmentResults = <span>Aucun résultat</span>;
        else
            equipmentResults = <table>
                <thead><tr>
                    <th>Type</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Numéro de série</th>
                    <th>Numéro PNC</th>
                </tr></thead>
                <tbody>{this.state.equipments.map((equipment) => <tr key={equipment.id} onClick={() => {
                    this.equipment.type.current.setValue(equipment.type.id);
                    this.equipment.brand.current.setValue(equipment.brand.id);
                    this.equipment.model.current.value = equipment.model;
                    this.equipment.serialNumber.current.value = equipment.serialNumber;
                    this.equipment.pnc.current.value = equipment.pnc;
                    this.other.symptoms.current.focus();
                    this.setState({ equipmentId: equipment.id });
                }}>
                    <td>{equipment.type.name}</td>
                    <td>{equipment.brand.name}</td>
                    <td>{equipment.model}</td>
                    <td>{equipment.serialNumber}</td>
                    <td>{equipment.pnc}</td>
                </tr>)}</tbody>
            </table>;

        const createFile = () => {

            this.setState({ requesting: true });
            newFile({
                client: this.state.clientId > 0 ? this.state.clientId : {
                    lastName: this.client.lastName.current.value,
                    firstName: this.client.firstName.current.value,
                    city: this.client.city.current.value,
                    postalCode: this.client.postalCode.current.value,
                    address: this.client.address.current.value,
                    phone: this.client.phone.current.value,
                    cellphone: this.client.cellphone.current.value,
                    email: this.client.email.current.value
                },
                equipment: this.state.equipmentId > 0 ? this.state.equipmentId : {
                    type: this.equipment.type.current.value,
                    brand: this.equipment.brand.current.value,
                    model: this.equipment.model.current.value,
                    serialNumber: this.equipment.serialNumber.current.value,
                    pnc: this.equipment.pnc.current.value
                },
                symptoms: this.other.symptoms.current.value,
                troubleShootingPayment: this.other.troubleShootingPayment.current.value,
                priority: this.other.priority.current.checked,
                date: this.state.selectedDate.getTime()
            }).then((fileId) => {
                this.props.navigate("/files/" + fileId);
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    window.location.reload();
                } else if (message === "Missing client firstName or lastName")
                    this.setState({ requesting: false, info: <Info>Nom ou prénom du client manquant</Info> });
                else if (message === "Missing client phone or cellphone")
                    this.setState({ requesting: false, info: <Info>Téléphone du client manquant</Info> });
                else if (message === "Invalid client postal code")
                    this.setState({ requesting: false, info: <Info>Code postal invalide</Info> });
                else if (message === "Missing equipment type")
                    this.setState({ requesting: false, info: <Info>Type de matériel manquant</Info> });
                else if (message === "Missing symptoms")
                    this.setState({ requesting: false, info: <Info>Symptômes manquants</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu</Info> });
            });
        }

        return <div className="newFile">

            <div className="title">{document.title}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div className="sectionName">Client</div>
            <button className="sectionClean" disabled={this.state.requesting} onClick={() => {
                this.client.lastName.current.value = "";
                this.client.firstName.current.value = "";
                this.client.address.current.value = "";
                this.client.city.current.value = "";
                this.client.postalCode.current.value = "";
                this.client.phone.current.value = "";
                this.client.cellphone.current.value = "";
                this.client.email.current.value = "";
                this.client.lastName.current.focus();
                this.setState({ clients: null, clientId: -1 });
            }}>Vider</button>
            <div className="section">
                <div className="form">
                    <div className="field">
                        <span>Nom : </span>
                        <input autoFocus ref={this.client.lastName} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.firstName.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Prénom : </span>
                        <input ref={this.client.firstName} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.postalCode.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Code postal : </span>
                        <input ref={this.client.postalCode} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.city.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Ville : </span>
                        <input ref={this.client.city} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.address.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Adresse : </span>
                        <input ref={this.client.address} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.phone.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Téléphone fixe : </span>
                        <input ref={this.client.phone} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.cellphone.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Téléphone portable : </span>
                        <input ref={this.client.cellphone} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.client.email.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Adresse email : </span>
                        <input ref={this.client.email} disabled={this.state.requesting} onChange={updateClients} onKeyDown={(event) => event.key === "Enter" ? this.equipment.type.current.focus() : null} />
                    </div>
                </div>
                <div className="separator" />
                <div className="results" style={{ maxHeight: "248px" }}>{clientResults}</div>
            </div>

            <div className="sectionName">Matériel</div>
            <button className="sectionClean" disabled={this.state.requesting} onClick={() => {
                this.equipment.type.current.clear();
                this.equipment.brand.current.clear();
                this.equipment.model.current.value = "";
                this.equipment.serialNumber.current.value = "";
                this.equipment.pnc.current.value = "";
                this.equipment.type.current.focus();
                this.setState({ equipments: null, equipmentId: -1 });
            }}>Vider</button>
            <div className="section">
                <div className="form">
                    <div className="field">
                        <span>Type : </span>
                        <SearchBar ref={this.equipment.type} disabled={this.state.requesting}
                            onChange={updateEquipments} onEnter={() => this.equipment.brand.current.focus()}
                            getOptions={async (value) => (await getEquipmentsTypes(value)).map((type) => ({ name: type.name, value: type.id }))} />
                    </div>
                    <div className="field">
                        <span>Marque : </span>
                        <SearchBar ref={this.equipment.brand} disabled={this.state.requesting}
                            onChange={updateEquipments} onEnter={() => this.equipment.model.current.focus()}
                            getOptions={async (value) => (await getEquipmentsBrands(value)).map((brand) => ({ name: brand.name, value: brand.id }))} />
                    </div>
                    <div className="field">
                        <span>Modèle : </span>
                        <input ref={this.equipment.model} disabled={this.state.requesting} onChange={updateEquipments}
                            onKeyDown={(event) => event.key === "Enter" ? this.equipment.serialNumber.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Numéro de série : </span>
                        <input ref={this.equipment.serialNumber} disabled={this.state.requesting} onChange={updateEquipments}
                            onKeyDown={(event) => event.key === "Enter" ? this.equipment.pnc.current.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Numéro PNC : </span>
                        <input ref={this.equipment.pnc} disabled={this.state.requesting} onChange={updateEquipments}
                            onKeyDown={(event) => event.key === "Enter" ? this.other.symptoms.current.focus() : null} />
                    </div>
                </div>
                <div className="separator" />
                <div className="results" style={{ maxHeight: "155px" }}>{equipmentResults}</div>
            </div>

            <div className="sectionName">Autres</div>
            <button className="sectionClean" disabled={this.state.requesting} onClick={() => {
                this.other.symptoms.current.value = "";
                this.other.troubleShootingPayment.current.value = "";
                this.other.priority.current.checked = false;
                this.other.symptoms.current.focus();
            }}>Vider</button>
            <div className="section">
                <div className="form">
                    <div className="field">
                        <span>Symptômes : </span>
                        <input ref={this.other.symptoms} disabled={this.state.requesting}
                            onKeyDown={(event) => event.key === "Enter" ? this.other.date.current.input.focus() : null} />
                    </div>
                    <div className="field">
                        <span>Paiement de la recherche de panne : </span>
                        <TroubleshootingPaymentInput ref={this.other.troubleShootingPayment} disabled={this.state.requesting}
                            onKeyDown={(event) => event.key === "Enter" ? this.other.date.current.input.focus() : null} />
                    </div>
                    <div className="field no-mobile-wrap">
                        <span>Fiche prioritaire : </span>
                        <input type="checkbox" ref={this.other.priority} disabled={this.state.requesting} />
                    </div>
                    <div className="field no-mobile-wrap">
                        <span>Date d'entrée : </span>
                        <DatePicker ref={this.other.date} disabled={this.state.requesting} dateFormat={"dd/MM/yyyy"} selected={this.state.selectedDate}
                            onChange={(date) => this.setState({ selectedDate: date || new Date() })}
                            onKeyDown={(event) => event.key === "Enter" ? this.other.createButton.current.click() : null}
                            customInput={<input className="date" />} />
                    </div>
                    <div className="field">
                        <button ref={this.other.createButton} disabled={this.state.requesting} onClick={createFile}>Créer la fiche</button>
                    </div>
                </div>
            </div>
        </div>
    }
}

// eslint-disable-next-line
export default (props) => <NewFile {...props} navigate={useNavigate()} />;
