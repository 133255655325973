import { Component } from "react";
import { Line } from "react-chartjs-2";
import { Info, Loading } from "./other";
import { getStatistics } from "./api";
import { getTranslatedMonth, getWeekNumber } from "./utils";

import "./styles/statistics.scss";

export default class Statistics extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, selectedStatistic: 0, statistics: null };
    }

    selectStatistic(statistic) {

        this.setState({ requesting: true, info: null, selectedStatistic: statistic, statistics: null });

        let since;
        let unit;
        if (statistic === 1) {
            since = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 11 * 7);
            unit = "week";
        } else if (statistic === 2) {
            since = Date.UTC(new Date().getFullYear(), new Date().getMonth() - 11, 1);
            unit = "month";
        } else if (statistic === 3) {
            since = Date.UTC(new Date().getFullYear() - 9, 0, 1);
            unit = "year";
        }

        getStatistics(since, unit).then((statistics) => {
            this.setState({ requesting: false, statistics });
        }).catch((message) => {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    componentDidMount() {
        this.selectStatistic(1);
    }

    render() {

        document.title = "Statistiques - Servelec SAV";

        const chartData = {
            labels: [],
            datasets: [
                {
                    label: "Nouvelles fiches",
                    data: [],
                    borderColor: "rgb(255, 150, 0)",
                    backgroundColor: "rgba(255, 150, 0, 0.5)",
                    borderWidth: 1,
                    tension: 0.4
                },
                {
                    label: "Fiches réparées",
                    data: [],
                    borderColor: "rgb(0, 200, 0)",
                    backgroundColor: "rgba(0, 200, 0, 0.5)",
                    borderWidth: 1,
                    tension: 0.4
                },
                {
                    label: "Fiches non-réparées",
                    data: [],
                    borderColor: "rgb(255, 0, 0)",
                    backgroundColor: "rgba(255, 0, 0, 0.5)",
                    borderWidth: 1,
                    tension: 0.4
                }
            ]
        };

        if (this.state.statistics) {
            this.state.statistics[0].statistics.forEach((stat) => {
                if (this.state.selectedStatistic === 1)
                    chartData.labels.push("Semaine " + getWeekNumber(new Date(stat.day)));
                else if (this.state.selectedStatistic === 2)
                    chartData.labels.push(getTranslatedMonth(new Date(stat.day)));
                else if (this.state.selectedStatistic === 3)
                    chartData.labels.push(new Date(stat.day).getFullYear());
            });
            this.state.statistics.find((stat) => stat.name === "New_Files").statistics.forEach((stat) => {
                chartData.datasets.find((dataset) => dataset.label === "Nouvelles fiches").data.push(stat.value);
            });
            this.state.statistics.find((stat) => stat.name === "Files_Repaired").statistics.forEach((stat) => {
                chartData.datasets.find((dataset) => dataset.label === "Fiches réparées").data.push(stat.value);
            });
            this.state.statistics.find((stat) => stat.name === "Files_Not_Repaired").statistics.forEach((stat) => {
                chartData.datasets.find((dataset) => dataset.label === "Fiches non-réparées").data.push(stat.value);
            });
        }

        return <div className="statistics">

            <div className="title">{document.title}</div>

            <div className="box">
                <div className="title">Statistiques Fiches</div>

                <div className="lists">
                    <button disabled={this.state.requesting} className={this.state.selectedStatistic === 1 ? "selected" : ""} onClick={() => this.selectStatistic(1)}>Semaines</button>
                    <button disabled={this.state.requesting} className={this.state.selectedStatistic === 2 ? "selected" : ""} onClick={() => this.selectStatistic(2)}>Mois</button>
                    <button disabled={this.state.requesting} className={this.state.selectedStatistic === 3 ? "selected" : ""} onClick={() => this.selectStatistic(3)}>Années</button>
                </div>

                {this.state.info}
                {this.state.requesting ? <Loading /> : null}

                {this.state.statistics
                    ? <Line data={chartData} options={{ scales: { y: { beginAtZero: true } } }} />
                    : null}
            </div>
        </div>;
    }
}
