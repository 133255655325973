import { Component, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Info, Loading, SearchBar } from "./other";
import { getLentEquipment, editLentEquipment, deleteLentEquipment, getEquipmentsTypes, getEquipmentsBrands, getLentEquipmentLoans, editLentEquipmentLoan, deleteLentEquipmentLoan } from "./api";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import "./styles/lentEquipments.scss";

class LentEquipment extends Component {

    constructor(props) {

        super(props);

        this.typeInput = createRef();
        this.brandInput = createRef();
        this.modelInput = createRef();
        this.serialNumberInput = createRef();
        this.pncInput = createRef();

        this.state = { requesting: false, info: null, lentEquipment: null, editing: false, selectedLastRevisionDate: null };
    }

    componentDidMount() {

        const id = parseInt(this.props.params.id);
        if (isNaN(id)) {
            this.setState({ info: <Info>Ce numéro d'appareil de prêt n'est pas valide !</Info> });
            return;
        }

        this.setState({ requesting: true, info: null });
        getLentEquipment(id).then((lentEquipment) => {
            this.setState({ lentEquipment, requesting: false });
        }).catch((error) => {
            if (error === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else if (error === "This lent equipment does not exist")
                this.setState({ requesting: false, info: <Info>Cet appareil de prêt n'existe pas !</Info> });
            else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = `Appareil de prêt ${this.state.lentEquipment ? this.state.lentEquipment.type.name + " " + this.state.lentEquipment.brand.name : ""} - Servelec SAV`;

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true, selectedLastRevisionDate: this.state.lentEquipment.lastRevisionDate ? new Date(this.state.lentEquipment.lastRevisionDate) : null }, () => {
                    this.typeInput.current.setValue(this.state.lentEquipment.type.id);
                    this.brandInput.current.setValue(this.state.lentEquipment.brand.id);
                });
                return;
            }

            const edits = {};
            if (this.typeInput.current.value !== this.state.lentEquipment.type.id) edits.type = this.typeInput.current.value;
            if (this.brandInput.current.value !== this.state.lentEquipment.brand.id) edits.brand = this.brandInput.current.value;
            if (this.modelInput.current.value !== this.state.lentEquipment.model) edits.model = this.modelInput.current.value;
            if (this.serialNumberInput.current.value !== this.state.lentEquipment.serialNumber) edits.serialNumber = this.serialNumberInput.current.value;
            if (this.pncInput.current.value !== this.state.lentEquipment.pnc) edits.pnc = this.pncInput.current.value;
            if ((this.state.selectedLastRevisionDate?.getTime() || null) !== this.state.lentEquipment.lastRevisionDate) edits.lastRevisionDate = this.state.selectedLastRevisionDate?.getTime() || null;

            if (Object.keys(edits).length === 0) {
                this.setState({ editing: false });
                return;
            }

            this.setState({ requesting: true, info: null });
            editLentEquipment(this.state.lentEquipment.id, edits).then((lentEquipment) => {
                this.setState({ lentEquipment, editing: false, requesting: false, selectedLastRevisionDate: null });
            }).catch(() => {
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDelete = () => {

            const valid = window.confirm("Voulez vous vraiment supprimer cet appareil de prêt ?");
            if (!valid) return;

            this.setState({ requesting: true, info: null });
            deleteLentEquipment(this.state.lentEquipment.id)
                .then(() => this.props.navigate("/lentEquipments"))
                .catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        return <div className="lentEquipment">

            <div className="title">Appareil de prêt{this.state.lentEquipment ? ` n°${this.state.lentEquipment.id}` : ""}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {this.state.lentEquipment ? <div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Type :</div>
                        {this.state.editing
                            ? <SearchBar ref={this.typeInput} disabled={this.state.requesting} onEnter={() => toggleEdit()}
                                getOptions={async (value) => (await getEquipmentsTypes(value)).map((type) => ({ name: type.name, value: type.id }))} />
                            : <div>{this.state.lentEquipment.type.name || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Marque :</div>
                        {this.state.editing
                            ? <SearchBar ref={this.brandInput} disabled={this.state.requesting} onEnter={() => toggleEdit()}
                                getOptions={async (value) => (await getEquipmentsBrands(value)).map((brand) => ({ name: brand.name, value: brand.id }))} />
                            : <div>{this.state.lentEquipment.brand.name || <i>Non précisée</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Modèle :</div>
                        {this.state.editing
                            ? <input ref={this.modelInput} defaultValue={this.state.lentEquipment.model} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.lentEquipment.model || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Numéro de série :</div>
                        {this.state.editing
                            ? <input ref={this.serialNumberInput} defaultValue={this.state.lentEquipment.serialNumber} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.lentEquipment.serialNumber || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Numéro PNC :</div>
                        {this.state.editing
                            ? <input ref={this.pncInput} defaultValue={this.state.lentEquipment.pnc} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.lentEquipment.pnc || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Dernière révision :</div>
                        {this.state.editing
                            ? <div className="last-revision-date">
                                <ReactDatePicker disabled={this.state.requesting} dateFormat={"dd/MM/yyyy"} selected={this.state.selectedLastRevisionDate}
                                    onChange={(date) => this.setState({ selectedLastRevisionDate: date || null })}
                                    onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null}
                                    customInput={<input className="date" />} />
                                <button onClick={() => this.setState({ selectedLastRevisionDate: new Date() })}>Aujourd'hui</button>
                                <button onClick={() => this.setState({ selectedLastRevisionDate: null })}>Inconnue</button>
                            </div>
                            : <div>{this.state.lentEquipment.lastRevisionDate ? "Le " + moment(this.state.lentEquipment.lastRevisionDate).format("DD/MM/YYYY") : "Inconnue"}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Créé le :</div>
                        <div>{moment(this.state.lentEquipment.createdDate).format("DD/MM/YYYY")}</div>
                    </div>
                </div>

                <div>
                    <button disabled={this.state.requesting} onClick={toggleEdit}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={() => this.setState({ editing: false, selectedLastRevisionDate: null })}>Annuler</button> : null}
                    {this.state.editing ? <button disabled={this.state.requesting} onClick={processDelete}>Supprimer</button> : null}
                </div>

                <div className="subtitle">Prêts :</div>
                <Loans lentEquipment={this.state.lentEquipment} />

            </div> : null}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <LentEquipment {...props} params={useParams()} navigate={useNavigate()} />;

class Loans extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, loans: [] };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getLentEquipmentLoans(this.props.lentEquipment.id)
            .then((loans) => this.setState({ requesting: false, loans }))
            .catch(() => this.setState({ requesting: false }));
    }

    render() {

        return <div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div className="loans">{this.state.loans.length === 0
                ? "Aucun prêt"
                : <table>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Fiche</th>
                            <th>Date de prêt</th>
                            <th>Rendu</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.loans.sort((a, b) => b.loanDate - a.loanDate).map((loan) => <Loan loan={loan} lentEquipment={this.props.lentEquipment} setRequesting={(requesting) => this.setState({ requesting })} onDelete={() => {
                            this.state.loans.splice(this.state.loans.indexOf(loan), 1);
                            this.setState({ loans: this.state.loans });
                        }} key={loan.id} />)}
                    </tbody>
                </table>}</div>

        </div>;
    }
}

class LoanClass extends Component {

    constructor(props) {

        super(props);

        this.returnedInputRef = createRef();

        this.state = { requesting: false, editing: false };
    }

    setRequesting(requesting) {
        this.props.setRequesting(requesting);
        this.setState({ requesting });
    }

    render() {

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true });
                return;
            }

            if (this.returnedInputRef.current.checked === !!this.props.loan.returnDate) {
                this.setState({ editing: false });
                return;
            }

            const edits = {};
            edits.returnDate = this.returnedInputRef.current.checked ? Date.now() : null;

            this.setRequesting(true);
            editLentEquipmentLoan(this.props.lentEquipment.id, this.props.loan.id, edits).then(() => {
                Object.assign(this.props.loan, edits);
                this.setRequesting(false);
                this.setState({ editing: false });
            }).catch(() => {
                this.setRequesting(false);
                this.setState({ editing: false });
            });
        }

        const processDelete = () => {
            this.setRequesting(true);
            deleteLentEquipmentLoan(this.props.lentEquipment.id, this.props.loan.id).then(() => {
                this.setRequesting(false);
                this.props.onDelete();
            }).catch(() => this.setRequesting(false));
        }

        return <tr className="loan">
            <td onClick={() => this.props.navigate("/clients/" + this.props.loan.client.id)} className="clickable">{this.props.loan.client.firstName + " " + this.props.loan.client.lastName}</td>
            {this.props.loan.fileId
                ? <td className="clickable" onClick={() => this.props.navigate("/files/" + this.props.loan.fileId)}>n°{this.props.loan.fileId}</td>
                : <td>Aucune</td>}
            <td>{moment(this.props.loan.loanDate).format("DD/MM/YYYY")}</td>
            <td>{!this.state.editing
                ? (this.props.loan.returnDate ? moment(this.props.loan.returnDate).format("DD/MM/YYYY") : "Non")
                : <input ref={this.returnedInputRef} type="checkbox" defaultChecked={!!this.props.loan.returnDate} disabled={this.state.requesting} />}</td>
            <td>
                <button onClick={toggleEdit} disabled={this.state.requesting}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                {this.state.editing ? <button onClick={processDelete} disabled={this.state.requesting}>Supprimer</button> : null}
            </td>
        </tr>;
    }
}

const Loan = (props) => <LoanClass {...props} navigate={useNavigate()} />;
